
import { Component, Vue } from 'vue-property-decorator'
import { SupplierDataList } from '@/types/material'

@Component({
  name: 'SuppliermanageList',
  components: {}
})
export default class extends Vue {
  private searchInfo = {
    supplierName: '',
    supplierType: ''
  }

  private typeList = []

  private page = 1
  private size = 10
  private total = 0
  private tableData: SupplierDataList[] =[]
  private loading = false

  created () {
    this.getData()
    this.getConfigList()
  }

  // 查询
  searchData () {
    this.page = 1
    this.getData()
  }

  // 获取供应商类型
  getConfigList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'supplierType' }).then(res => {
      this.typeList = res.supplierType || []
    })
  }

  // 获取表格数据
  getData () {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<SupplierDataList>}>(this.$apis.material.selectYhSupplierByPage, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    }).then((res) => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  // 新增
  add () {
    this.$router.push('/suppliermanage/add')
  }

  edit (supplierId: string) {
    this.$router.push({
      path: '/suppliermanage/editd',
      query: {
        supplierId
      }
    })
  }

  goDetail (supplierId: string) {
    this.$router.push({
      path: '/suppliermanage/detail',
      query: {
        supplierId
      }
    })
  }

  onDelete (supplierId: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.material.deleteYhSupplier, {
        supplierId: supplierId
      }).then(() => {
        this.$message.success('删除成功')
        this.searchData()
      })
    })
  }
}
